////////////////////
// *** FOOTER *** //
////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mixins' as m;
@use 'abstracts/mediaQueries' as mq;

footer {
  bottom: 0;
  width: 100%;

  .inner {
    padding-bottom: 1rem;

    .footer-image-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin: 2rem 0;

      @include mq.tablet {
        flex-wrap: wrap;
        margin-bottom: 3.5rem;
      }

      .footer-logo {
        cursor: pointer;
        display: block;
        width: 9rem;
        height: auto;
        margin: 0 1rem 0 0;

        @include mq.tablet {
          width: 268px;
        }
      }

      .claim {
        width: 11rem;
        height: auto;
        margin: 10px 0 0 auto;

        @include mq.tablet {
          width: 268px;
          margin-top: 12px;
        }

        &.left-aligned {
          margin: 10px auto 0 0;
        }
      }
    }

    .footer-content {
      @include mq.tablet {
        @include m.flex-box($align-items: center, $justify-content: space-between);
        padding: 0;
      }

      @include m.flex-box($direction: column, $align-items: center, $justify-content: space-between);
      width: 100%;

      &:before {
        border-top: 5px solid v.$brand-standard-blue;
        content: '';
        display: block;
        width: 100%;
      }

      .footer-copyright {
        min-height: 48px;
        display: flex;
        align-items: center;

        a,
        span {
          font-size: 1rem;
        }

        .icon {
          padding: 0 0.25rem;
          width: 1.75rem;
          height: 1.25rem;
          display: inline-block;
          @include m.colored-bg-svg($icon: 'helper/copyright.svg');
        }

        a {
          color: v.$brand-standard-blue;
          text-decoration: none;
          padding-left: 0.5rem;

          @include mq.desktop {
            padding-left: 1.25rem;
          }

          &:hover {
            text-decoration: none;
            color: v.$secondary-highlight-blue;
          }
        }
      }

      .footer-links {
        font-size: 1rem;
        padding: 0 0 1rem 0;
        text-align: center;
        min-height: 48px;
        display: flex;
        align-items: center;

        @include mq.tablet {
          margin-left: auto;
          padding: 0;
        }

        a {
          color: v.$brand-standard-blue;
          text-decoration: none;
          display: inline-block;

          &:after {
            content: '';
            margin-left: 0.5rem;
            margin-right: 0.5rem;

            @include mq.desktop {
              margin-left: 1rem;
              margin-right: 1rem;
            }
          }

          &:hover {
            color: v.$secondary-highlight-blue;
          }

          &:last-child {
            &:after {
              margin: 0;
              display: none;
            }
          }
        }
      }
    }

    .footer-socials {
      display: flex;

      a {
        display: inline-block;
        padding: 0 0.5rem;
        color: v.$brand-standard-blue;

        @include mq.desktop {
          padding: 0 0.75rem;
        }

        &:last-child {
          padding: 0 0 0 0.5rem;
          @include mq.desktop {
            padding: 0 0 0 0.75rem;
          }
        }

        .icon {
          width: 1.25rem;
          height: 1.25rem;

          @include mq.desktop {
            width: 1.5rem;
            height: 1.5rem;
          }

          @include m.colored-bg-svg($size: 80%);

          &.linkedin {
            mask-image: url(/shared-assets/icons/helper/linkedin.svg);
          }
          &.facebook {
            mask-image: url(/shared-assets/icons/helper/facebook.svg);
          }
          &.twitter {
            mask-image: url(/shared-assets/icons/helper/twitter.svg);
          }

          &:hover {
            background-color: v.$secondary-highlight-blue;
          }
        }
      }
    }
  }
}
