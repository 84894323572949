///////////////////////
// *** FUNCTIONS *** //
///////////////////////

/* FUNCTION TO DETERMINE DARK OR LIGHT COLOR AND APPLY BEST CONTRAST COLOR */
$dark-text-default: black !default;
$light-text-default: white !default;

// Calculate brightness of a given color.
@function brightness($color) {
  @return ((red($color) * 0.299) + (green($color) * 0.587) + (blue($color) * 0.114)) / 255 * 100%;
}

// Compares contrast of a given color to the light/dark arguments and returns whichever is most "contrasty"
@function color-contrast($color, $dark: $dark-text-default, $light: $light-text-default) {
  @if $color == null {
    @return null;
  } @else {
    $color-brightness: brightness($color);
    $light-text-brightness: brightness($light);
    $dark-text-brightness: brightness($dark);

    @return if(abs($color-brightness - $light-text-brightness) > abs($color-brightness - $dark-text-brightness), $light, $dark);
  }
}
