////////////////////
// *** MIXINS *** //
////////////////////

@use 'variables' as v;

@mixin flex-box($direction: row, $align-items: center, $justify-content: space-between, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin centered-box($direction: column, $textAlign: center) {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: $textAlign;
  flex-direction: $direction;
}

@mixin line-clamp($line-count: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-count;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@mixin ellipsis($width: 100%) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}

@mixin colored-bg-svg($color: v.$brand-standard-blue, $icon: 'basic/favourites.svg', $size: contain, $position: center) {
  background-color: $color;
  mask-image: url(/shared-assets/icons/#{$icon});
  mask-size: $size;
  mask-repeat: no-repeat;
  mask-position: $position;
}

@mixin uploading-files($justifyContent: center, $paddingTop: 0) {
  .uploading-files {
    background-color: v.$white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 900;
    border-radius: 0;

    .upload-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: $justifyContent;
      flex-wrap: wrap;
    }
  }
}

@mixin loading-content($top: 0, $left: 0, $width: 100%, $height: 100%, $borderradius: 0, $opacity: 1) {
  .loading-content {
    background-color: rgba(v.$white, $opacity);
    position: absolute;
    width: $width;
    min-width: 6rem;
    height: $height;
    min-height: 4rem;
    top: $top;
    left: $left;
    z-index: 999;
    border-radius: $borderradius;

    .spinner {
      left: 50%;
      margin: 0 auto;
      position: absolute;
      top: min(20rem, 50%);
      transform: translate(-50%, -50%);
      width: 6rem;
      text-align: center;
    }

    .spinner > div {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.25rem;
      background-color: v.$brand-standard-blue;
      border-radius: 50%;
      display: inline-block;
      animation: bouncedelay 1.5s infinite ease-in-out both;
    }

    .spinner .bounce1 {
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      animation-delay: -0.16s;
    }

    @keyframes bouncedelay {
      0%,
      80%,
      100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1);
      }
    }
  }
}

@mixin box-shadow($offset-y: 2px, $offset-blur: 5px) {
  -moz-box-shadow: $offset-y 0 $offset-blur 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: $offset-y 0 $offset-blur 0 rgba(0, 0, 0, 0.1);
  box-shadow: $offset-y 0 $offset-blur 0 rgba(0, 0, 0, 0.1);
}

@mixin mask-size($mask-size) {
  mask-size: $mask-size;
  -webkit-mask-size: $mask-size;
}

@mixin mask-repeat($mask-repeat) {
  mask-repeat: $mask-repeat;
  -webkit-mask-repeat: $mask-repeat;
}

@mixin mask-position($mask-position) {
  mask-position: $mask-position;
  -webkit-mask-position: $mask-position;
}

@mixin mask-image($mask-url) {
  mask-image: url($mask-url);
  -webkit-mask-image: url($mask-url);
}

@mixin mask($mask-position: center, $mask-size: contain, $mask-repeat: no-repeat) {
  @include mask-position($mask-position);
  @include mask-size($mask-size);
  @include mask-repeat($mask-repeat);
}

@mixin small-single-spinner {
  margin-left: 8px;
  border: 3.5px solid darken(v.$special-pale-blue, 10%);
  border-radius: 50%;
  border-top: 3.5px solid v.$secondary-highlight-blue;
  height: 24px;
  width: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: relative;
  top: 0;
  left: 0;
}

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin word-wrap($hyphens: auto) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: $hyphens;
  -moz-hyphens: $hyphens;
  -webkit-hyphens: $hyphens;
  hyphens: $hyphens;
}
