/////////////////////////
// *** GRID System *** //
/////////////////////////

@use 'abstracts/mediaQueries' as mq;

/* MASTER GRID AS A MIXIN */
@mixin masterGrid($columns: 12, $keepApectRatio: false, $ratio: 100%, $wrapOnMobile: true, $autoFit: false, $staticColumns: false) {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  padding: 0;
  display: grid;
  grid-gap: 1rem;

  @include mq.desktop {
    grid-gap: 1.5rem;
    @if $staticColumns == true {
      $width: calc(100% / #{$columns} - (1.5rem * (#{$columns} - 1) / #{$columns}));
      grid-template-columns: repeat($columns, $width);
    }
  }

  @if $autoFit == true {
    grid-template-columns: repeat(auto-fit, minmax(222px, 1fr));
  } @else {
    @if $staticColumns == true {
      $width: calc(100% / #{$columns} - (1rem * (#{$columns} - 1) / #{$columns}));
      grid-template-columns: repeat($columns, $width);
    } @else {
      @if $columns > 3 {
        grid-template-columns: repeat(3, 1fr);
      } @else {
        grid-template-columns: repeat($columns, 1fr);
      }
      @include mq.mobile {
        @if $columns > 6 {
          grid-template-columns: repeat(6, 1fr);
        } @else {
          grid-template-columns: repeat($columns, 1fr);
        }

        @include mq.tablet {
          @if $columns > 9 {
            grid-template-columns: repeat(9, 1fr);
          } @else {
            grid-template-columns: repeat($columns, 1fr);
          }

          @include mq.desktop {
            @if $columns > 12 {
              grid-template-columns: repeat(12, 1fr);
            } @else {
              grid-template-columns: repeat($columns, 1fr);
            }
          }
        }
      }
    }
  }

  .gridItem,
  .grid-item,
  > * {
    position: relative;
    width: 100%;
    min-height: auto;

    @if $wrapOnMobile == true {
      grid-column: 1 / -1;
    }

    @include mq.tablet {
      grid-column: auto / span 1;

      &.span2 {
        grid-column: auto / span 2;
      }
      &.span3 {
        grid-column: auto / span 3;
      }
      &.span4 {
        grid-column: auto / span 4;
      }
      &.span5 {
        grid-column: auto / span 5;
      }
      &.span6 {
        grid-column: auto / span 6;
      }
      &.span7 {
        grid-column: auto / span 7;
      }
      &.span8 {
        grid-column: auto / span 8;
      }
      &.span9 {
        grid-column: auto / span 9;
      }
      &.span10 {
        grid-column: auto / span 10;
      }
      &.span11 {
        grid-column: auto / span 11;
      }
      &.spanAll {
        grid-column: 1 / -1;
      }
    }

    @if $keepApectRatio == true {
      &:before {
        content: '';
        display: inline-block;
        height: 0;
        width: 1px;
        padding-bottom: $ratio;
      }

      .content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
