////////////////////////////
// *** GLOBAL STYLES *** //
////////////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;
@use 'layout/grid' as g;

:root {
  --safe-area-inset-top: const(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: const(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --app-height: 100%;
}
.withBottomMarginTablet {
  margin-bottom: 55px;
}

.withBottomMargin {
  margin-bottom: 130px;
}

.withoutBottomMargin {
  margin-bottom: 0;
}

html {
  &.noScrolling {
    min-height: auto;
    max-height: auto;
    overflow: hidden;
    height: 100vh;
  }
}

body {
  font-family: v.$plain-font;
  font-size: v.$font-size;
  line-height: v.$line-height;
  color: v.$brand-standard-blue;
  scroll-behavior: smooth;
  width: 100%;
  -webkit-text-size-adjust: none;

  /** [DP-331] Fix for 'scrollPositionRestoration: "top"' issue for firefox browser */
  @-moz-document url-prefix() {
    scroll-behavior: auto;
  }

  .root {
    background-color: #ffffff;
  }

  .mainContentArea {
    min-height: calc(100vh - 458px);
    @include mq.tablet {
      min-height: calc(100vh - 372px);
    }
  }

  &.anonymous {
    .mainContentArea {
      min-height: calc(100vh - 274px);
    }
  }

  &.freeze {
    overflow-y: hidden;
    position: fixed;
    left: 0;
    right: 0;
  }
}

.content-wrapper {
  width: 100%;
  position: relative;

  @include mq.tablet {
    min-height: calc(100vh - 274px);
  }

  &.anonymous {
    min-height: calc(100vh - 274px);
    section {
      margin: 2rem 0;
      @include mq.tablet {
        margin: 4rem 0;
      }
    }
  }
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;

  @include mq.desktop {
    max-width: calc(1440px + 7.5rem);
    padding: 0 3.75rem;
  }

  &.veryNarrow {
    @include mq.desktop {
      max-width: calc(1024px + 7.5rem);
    }
  }
}

/* SOME GENERAL GLOBAL HELPER CLASSES */
hr {
  border: none;
  height: 1px;
  background: v.$special-pale-blue;
  margin: 0.75rem 0;
}

.hide {
  display: none;
}

.veryTinySpacer {
  height: 0.5rem;
}

.tinySpacer {
  height: 1rem;
}

.spacer {
  height: 2rem;
}

.bigSpacer {
  height: 4rem;
}

.massiveSpacer {
  height: 10rem;
}

// Global Rule to handle spacing on Content Preview Page (HCMS)
.cmsPreview .sectionContainer > *:not(.inner),
.cmsPreview .sectionContainer > .inner > * {
  margin: 1.5rem auto 4rem auto;
  display: block;
}

// Global Rule to handle max text spacing on dynamic landing pages
.contentComposition > div.mainContent {
  > *[class^='contentArea'] {
    &.contentArea1Container {
      app-dynamic-page-teaser,
      .no-top-spacing {
        margin-top: 0 !important;
      }
    }

    > * {
      display: block;
      margin: 2rem 0;
      @include mq.tablet {
        margin: 4rem 0;
      }
    }
    > app-headline-helper {
      margin-bottom: -0.75rem !important;
      @include mq.tablet {
        margin-bottom: -2.5rem !important;
      }
    }
    > app-dynamic-content-teaser {
      margin: 2rem 0;
      @include mq.tablet {
        margin: 4rem 0;
      }
    }
    .mainTextArea {
      p {
        padding: 0 0 1.5rem 0;
        &.lineClamped {
          @include m.line-clamp($line-count: 2);
          min-height: 3rem;
          padding: 0;
          margin: 0 0 1.5rem 0;
        }
        &:last-child {
          padding: 0;
        }
      }
      ul {
        padding-bottom: 1.5rem;
        li > p {
          padding: 0.25rem;
        }
      }
      h1,
      h2,
      h3 {
        padding: 0 0 1rem 0;
        &.lineClamped {
          @include m.line-clamp($line-count: 1);
        }
      }
    }
  }
}

// Global Rule to render CMS tables properly
.cmsTable {
  min-width: 820px;
  tr {
    th {
      padding: 0.5rem 1.5rem 0.2rem 0;
      border-bottom: 2px solid v.$brand-bright-grey;

      p {
        font-family: v.$bold-font;
        text-align: left;
        text-transform: uppercase;
        color: v.$brand-mid-grey;
        font-size: v.$font-size-xxs;
        line-height: v.$line-height-headlines;
        padding-bottom: 0.25rem;
      }

      &.firstCell {
        p {
          color: v.$brand-standard-blue;
        }
      }
    }

    td {
      padding: 0.5rem 1.5rem 0.5rem 0;
      border-bottom: 1px solid v.$brand-bright-grey;

      p {
        color: v.$brand-mid-grey;
      }

      &.firstCell {
        p {
          font-family: v.$bold-font;
          color: v.$brand-standard-blue;
        }
      }
    }
  }
}

// Global rule to render imageTextCombination properly
.dynamicImageTextCombination .textContentArea .paragraphsSection,
.dynamicImageTextCombination .onlyImageArea .textContainer .paragraphsSection {
  padding-top: 0.25rem;
  p {
    padding: 1.5rem 0 0 0;
  }
}
.dynamicImageTextCombination .textContainer .bodyCopySection {
  > p {
    padding: 0 0 1.25rem 0;
  }
  ul {
    /* margin: -1rem 0 1.5rem 2rem; */
    margin: 0 0 1.5rem 2rem;
  }
}

// Global rule to align imageCards in a mashUp component properly
.mashUpWrapper {
  .imageCardContainer {
    &.layout_twoColumns,
    &.layout_2-columns {
      app-dynamic-image-card:nth-child(odd) > .imageCardWrapper {
        float: right;
      }
      app-dynamic-image-card:nth-child(even) > .imageCardWrapper {
        float: left;
      }
    }
  }
}

#xm-survey-videos .scrollable {
  height: 68px !important;
}

// JSON Formatter
.json-container {
  font-family: menlo, consolas, monospace;
  font-style: normal;
  font-weight: bold;
  line-height: 1.4em;
  font-size: 0.9rem;
  transition: background-color 400ms;
}
a.json-link {
  text-decoration: none;
  border-bottom: 1px solid;
  outline: none;
}
a.json-link:hover {
  background-color: transparent;
  outline: none;
}
ol.json-lines {
  white-space: normal;
  padding-inline-start: 3em;
  margin: 0px;
}
ol.json-lines > li {
  white-space: pre;
  text-indent: 0.7em;
  line-height: 1.5em;
  padding: 0px;
}
ol.json-lines > li::marker {
  font-family: system-ui, sans-serif;
  font-weight: normal;
}
.json-key,
.json-string,
.json-number,
.json-boolean,
.json-null,
.json-mark,
a.json-link,
ol.json-lines > li {
  transition: all 400ms;
}

.json-container {
  background-color: white;
}
.json-key {
  color: brown;
}
.json-string {
  color: olive;
  white-space: break-spaces;
}
.json-number {
  color: navy;
}
.json-boolean {
  color: teal;
}
.json-null {
  color: dimgray;
}
.json-mark {
  color: black;
}
a.json-link {
  color: purple;
}
a.json-link:visited {
  color: slategray;
}
a.json-link:hover {
  color: blueviolet;
}
a.json-link:active {
  color: slategray;
}
ol.json-lines > li::marker {
  color: white;
}
ol.json-lines > li:nth-child(odd) {
  background-color: transparent;
}
ol.json-lines > li:nth-child(even) {
  background-color: transparent;
}
ol.json-lines > li:hover {
  background-color: transparent;
}
