@use 'abstracts/variables' as *;
@use 'abstracts/mediaQueries' as *;

.switch-container {
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    @include tablet {
      width: 50px;
      height: 30px;
    }
  }
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-bright-grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    background-color: $white;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;

    @include tablet {
      height: 24px;
      width: 24px;
      left: 3px;
      bottom: 3px;
    }
  }

  input:checked + .slider {
    background-color: $secondary-highlight-blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $secondary-highlight-blue;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    @include tablet {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
