////////////////////////////////////
// *** EXPANDABLE TEXT STYLES  *** //
////////////////////////////////////

div.expandableTextWrapper {
  position: relative;

  p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    height: 1.75rem;
    white-space: pre-wrap;

    &.expanded {
      display: block;
      height: 100% !important;
    }
  }
}
