////////////////////////
// *** TYPOGRAPHY *** //
////////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;

/* GENRAL TAGS */
strong {
  font-family: v.$bold-font;
  font-weight: 400;
}

input {
  font-family: v.$plain-font;
}

/* PARAGRAPHS AND COPY TEXT */
/* copy2 is going to be default. copy1 is larger, copy3 is smaller */
.foreignHTML {
  .hideInDPortal {
    display: none;
  }
}

p {
  font-family: v.$plain-font;
  font-size: v.$font-size;
  line-height: v.$line-height;
  color: v.$brand-dark-grey;
  margin: 0;
  padding: 0;

  &.grey {
    color: v.$brand-mid-grey;
  }

  &.error {
    padding: 1rem 1.5rem;
    margin: 1rem auto;
    color: v.$signal-magenta;
    background-color: rgba(v.$signal-magenta, 10%);
    border: 1px solid v.$signal-magenta;
    border-radius: v.$teaser-border-radius;
    display: inline-block;
  }

  &.addPadding {
    padding-bottom: v.$s;
  }

  &.copy1 {
    font-size: v.$font-size-xs;

    @include mq.tablet {
      font-size: v.$font-size-s;
    }
  }

  &.copy2 {
    font-size: v.$font-size;
  }

  &.copy3 {
    font-size: v.$font-size-xxs2;
  }

  &.copy4 {
    font-size: v.$font-size-xxs3;
  }

  &.label {
    font-family: v.$bold-font;
    font-size: v.$font-size-xs;
    text-transform: uppercase;
    color: v.$brand-dark-grey;

    span {
      display: inline-block;
      padding: 0 0.15rem;
    }
    &.black {
      color: v.$brand-black;
    }
    &.inTile {
      font-family: v.$plain-font;
      font-size: v.$font-size-xxs2;
      text-transform: initial;
    }
  }
}

/* LINKS */
a {
  color: v.$interaction-default;
  text-decoration: underline;

  &.dark {
    color: v.$brand-standard-blue;

    &:hover,
    &:visited {
      text-decoration: none;
      color: v.$brand-mid-blue;
    }
  }

  &:hover,
  &.hovered {
    text-decoration: none;
    color: v.$interaction-hover;
  }

  &.disabled {
    color: v.$interaction-disabled;
    cursor: not-allowed;

    &:hover,
    &.hovered {
      text-decoration: underline;
    }
  }
}

/* NEW ELEMENT: Overline */
p.overline,
span.overline,
p.subline,
span.subline {
  font-family: v.$light-font;
  font-size: v.$font-size-s;
  color: v.$brand-standard-blue;
  @include mq.tablet {
    font-size: v.$font-size-m;
  }
}
p.overline-small,
span.overline-small,
p.subline-small,
span.subline-small {
  font-family: v.$light-font;
  font-size: v.$font-size-xxs;
  color: v.$brand-standard-blue;
}

/* HEADLINES & SUBHEADLINES */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: v.$bold-font;
  font-weight: 400;
  line-height: v.$line-height-headlines;
  margin: 0;
  padding: 0;
  color: v.$brand-standard-blue;

  &.addPadding {
    padding-bottom: v.$xs;
  }

  span.unBold {
    font-family: v.$light-font !important;
  }
}

h1 {
  font-size: v.$font-size-l;
  @include mq.tablet {
    font-size: v.$font-size-xl;
  }

  &.special {
    font-size: v.$font-size-xl;
    line-height: v.$line-height-special;
    padding-bottom: 0.33rem;
    @include mq.tablet {
      font-size: v.$font-size-xxl;
      padding-bottom: 0.4rem;
    }
  }
}

h2 {
  font-size: v.$font-size-m;
  color: v.$brand-black;
  @include mq.tablet {
    font-size: v.$font-size-l;
  }
}

h3 {
  font-size: v.$font-size-s;
  color: v.$brand-black;
  @include mq.tablet {
    font-size: v.$font-size-m;
  }
}

/* H5 and H6 will never be used */
h4,
h5,
h6 {
  color: v.$brand-black;
  font-size: v.$font-size-xs;
}

/* ON DARK OR IMAGE BACKGROUNDS = inverted mode */
.inverted {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span.overline,
  span.overline-small,
  span.subline,
  span.subline-small {
    color: v.$white;
  }

  a.disabled {
    color: v.$interaction-disabled;
  }
}

/* SPECIAL HEADLINES (Headlines with "Show more link" on the rigth) */
.headlineWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 1.25rem 0;

  @include mq.tablet {
    flex-wrap: nowrap;
    padding: 0 0 2rem 0;
  }

  .showAllLink {
    width: 100%;

    @include mq.tablet {
      width: auto;
    }

    button.text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      max-width: 100%;
    }
  }

  &.noPadding {
    padding: 0;
  }
}

.textArea {
  h2,
  h3 {
    padding: 1.5rem 0 0.5rem;
  }
  p {
    padding: 0.25rem 0 1rem 0;

    @include mq.tablet {
      padding: 0.25rem 2rem 1rem 0;
    }
  }
}

// General List Types
ol,
.textArea ol {
  margin-left: 1rem;
  li p {
    margin: 0;
    padding: 0.25rem;
  }
}

ul:not(.checkmarkList):not(.benefitsList):not(.hideBulletPoints),
.textArea ul:not(.checkmarkList):not(.benefitsList):not(.hideBulletPoints) {
  list-style-type: disc;
  margin-left: 1.25rem;
  li p {
    margin: 0;
    padding: 0.25rem;
  }
}

ul.checkmarkList,
.textArea ul.checkmarkList {
  margin-left: 2.1rem;
  li {
    position: relative;
    p {
      padding: 0.25rem;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -2rem;
      height: 2rem;
      width: 2rem;
      @include m.colored-bg-svg($color: v.$brand-standard-blue, $icon: 'basic/check-mark.svg', $size: 60%);
    }
  }
}

.inverted .textContainer ul.checkmarkList {
  li::before {
    background-color: v.$white;
  }
}
