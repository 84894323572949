////////////////////////////////////
// *** BUTTONS, TOGGLES, ETC. *** //
////////////////////////////////////

@use 'abstracts/variables' as v;

/* TOOLTIPS */
.mat-tooltip-panel {
  .mat-tooltip {
    background-color: v.$brand-standard-blue;
    overflow: visible;
    border-radius: v.$tile-border-radius;
    font-size: v.$font-size-xxs2;
    line-height: 130%;
    text-align: center;
    white-space: nowrap;
    padding: 0.5rem 1rem;

    &.inverted {
      background-color: v.$white;
      color: v.$brand-standard-blue;
    }

    &.small {
      padding: 0.25rem 0.5rem;
      font-size: v.$font-size-xxs3;
      line-height: v.$line-height-headlines;
      border-radius: v.$small-border-radius;
    }
  }

  &.mat-tooltip-panel-above {
    .mat-tooltip::after {
      content: '';
      position: absolute;
      top: 100%;
      bottom: auto;
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: v.$brand-standard-blue transparent transparent transparent;
    }

    .mat-tooltip.inverted::after {
      border-color: v.$white transparent transparent transparent;
    }
  }

  &.mat-tooltip-panel-below {
    .mat-tooltip::after {
      content: '';
      position: absolute;
      top: auto;
      bottom: 100%;
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent v.$brand-standard-blue transparent;
    }

    .mat-tooltip.inverted::after {
      border-color: transparent transparent v.$white transparent;
    }
  }

  &.mat-tooltip-panel-left {
    .mat-tooltip::after {
      content: '';
      position: absolute;
      top: 50%;
      bottom: auto;
      left: 100%;
      right: auto;
      margin-top: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent transparent v.$brand-standard-blue;
    }

    .mat-tooltip.inverted::after {
      border-color: transparent transparent transparent v.$white;
    }
  }

  &.mat-tooltip-panel-right {
    .mat-tooltip::after {
      content: '';
      position: absolute;
      top: 50%;
      bottom: auto;
      left: auto;
      right: 100%;
      margin-top: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent v.$brand-standard-blue transparent transparent;
    }

    .mat-tooltip.inverted::after {
      border-color: transparent v.$white transparent transparent;
    }
  }

  &.mat-tooltip-panel-above,
  &.mat-tooltip-panel-below,
  &.mat-tooltip-panel-left,
  &.mat-tooltip-panel-right {
    .mat-tooltip.small {
      &::after {
        content: '';
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        margin: 0;
        border-width: 0px;
        border-style: none;
        border-color: transparent;
      }
    }
  }
}
